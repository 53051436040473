<template>
  <div class="sale_wrapper">
    <div>
      <div class="base_box node_box">
        <div class="switch_box both_side">
          <div>
            <div class="align_center delay">
              <img src="../assets/img/wifi.png" alt="" srcset="" />{{nodeTime}} ms
            </div>
            <div class="link">{{ rpcUrl }}</div>
          </div>
          <div class="switch_btn" @click="clickNode">切換節點</div>
        </div>
        <div class="base_title">選擇公鏈</div>
        <div
          class="base_select mt20"
        >
          <div class="content">
            <img
              src="../assets/img/bsc.png"
              class="select_img"
              srcset=""
            />Binance Smart Chain
          </div>
         
        </div>
        <div class="base_title">{{$t('sale.5')}}</div>
        <div
          class="base_select mt20"
          :class="{ select_active: show2 }"
        >
          <div class="content">
            <img
               :src="exchange[selectEx].logo"
              class="select_img"
              srcset=""
            />{{ exchange[selectEx].name }}
          </div>
          <div class="right_down" @click.stop="show2 = !show2"></div>
          <div class="downlist" v-click-outside="handleScroll">
            <div class="list_item" v-for="(item, index) in exchange"
                    :key="item.name"
                    @click="switchExchange(index)">
              <img
                :src="item.logo"
                class="select_img"
                srcset=""
              />{{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="base_box private_box">
        <div class="align_center">
          <div class="amount_item">
            <div class="title">BNB 餘額</div>
            <div class="text">{{ wallet.showBalance }}</div>
          </div>
          <!-- <div class="amount_item">
            <div class="title">USDT 餘額</div>
            <div class="text">--</div>
          </div> -->
        </div>
        <div class="expiree_time">
          &nbsp;&nbsp;•&nbsp;&nbsp;{{ $t("tool.22") }} {{ showEndTime }}
        </div>
        <div class="base_btn load_btn" @click="clickPrivateKey">載入私鑰</div>
        <div class="load_desc">
          <li>強烈推薦使用小額資產的錢包進行搶幣</li>
          <li>載入私鑰是為了省去確認操作，直接實現快速自動買賣</li>
        </div>
      </div>
    </div>
    <div class="base_box main_box">
      <div class="token_info" v-if="saleInfo.tokenSymbol">
        <div class="token_item40">
          <div class="info_label">{{ saleInfo.tokenSymbol }}</div>
          <div class="info_text">{{ saleInfo.tokenName }}</div>
        </div>
        <div class="token_item60">
          <div class="info_label">預售時間~结束時間</div>
          <div class="info_text">{{ saleInfo.startTime }} ~ {{ saleInfo.endTime }}</div>
        </div>
        <div class="token_item100">
          <div class="info_label">创建者地址</div>
          <div class="info_text">
            {{ saleInfo.owner }}
          </div>
        </div>
        <div class="token_item100">
          <div class="info_label">預售地址</div>
          <div class="info_text">
            {{ saleInfo.saleAddress }}
          </div>
        </div>
        <div class="token_item100" style="margin-bottom: 0;">
          <div class="info_label">代幣地址</div>
          <div class="info_text">
            {{ saleInfo.tokenAddress }}
          </div>
        </div>
      </div>
      <div class="base_title">預售地址</div>
      <div class="base_flex mt20">
        <div class="base_input">
          <input type="text" placeholder="请輸入地址" v-model="saleUrl"
            @change="handleSaleUrlChange"/>
        </div>
        <div class="base_btn approve_btn" @click="confirmSaleUrl">获取預售信息</div>
      </div>
      <div class="base_title">搶購BNB數量</div>
      <div class="base_input mt20">
        <input type="text" placeholder="请輸入買入金額" v-model="amountIn"/>
      </div>
      <div class="base_title">搶購速度</div>
      <div style="padding: 0 7px" class="mt20">
        <vue-slider :tooltip-formatter="formatter1" :min="1" :interval="0.1" v-model="gasMulti" height="8px"></vue-slider>
      </div>
      <div class="base_tips">
        <img src="../assets/img/info2.png" class="base_info2" srcset="" />{{
          $t("tool.44", [
            gasMulti,
            formatDecimal(gasPrice * gasMulti) + "BNB",
            formatDecimal(gasPrice * 2 * gasMulti) + "BNB",
          ])
        }}
      </div>
      <div class="col_2 mt20 mobile_br">
        <div class="col_2_item mboile_20">
          <!-- Hash检查 -->
        <div class="switch_box">
          <span class="name">{{$t('scientist.4')}}</span>
          <Tooltip
            max-width="300"
            :content="$t('scientist.5')"
            placement="top"
          >
            <img src="../assets/img/question.png" class="quest" srcset />
          </Tooltip>
          <i-switch
            size="large"
            :disabled="gasMode" v-model="status" @on-change="change"
          />
        </div>
        </div>
        <div class="col_2_item mboile_0">
          <!-- 失败重试 -->
        <div class="switch_box inputset_box2">
          <span class="name">{{ $t('sale.6') }}</span>
          <Tooltip max-width="300" :content="$t('sale.7')" placement="top">
            <img src="../assets/img/question.png" class="quest" srcset />
          </Tooltip>
          <i-switch
            size="large"
            :disabled="gasMode" v-model="funStatus" @on-change="changeFun"
          />
        </div>
        </div>
      </div>
      
      <div class="align_center space_betw mt20">
        <!-- 狂暴模式: -->
        <div class="switch_box">
          <span class="name">{{$t('sale.8')}}</span>
          <Tooltip
            max-width="300"
            :content="$t('sale.9')"
            placement="top"
          >
            <img src="../assets/img/question.png" class="quest" srcset />
          </Tooltip>
          <i-switch
            size="large"
            v-model="gasMode"
              @on-change="changeGasMode"
          />
        </div>
      </div>

      <div class="col_2 mt20 mobile_br" v-show="gasMode">
        <div class="col_2_item mboile_20">
          <!-- 提前下单 (秒) -->
          <div class="switch_box inputset_box">
            <span class="name">{{$t('sale.12')}}</span>
            <Tooltip max-width="300" :content="$t('sale.13')" placement="top">
              <img src="../assets/img/question.png" class="quest" srcset />
            </Tooltip>
            <div class="base_input ml10">
              <input
                type="text"
                v-model="delayInterval"
                onkeyup="value=value.replace(/[^a-z0-9_]/g,'');"
              />
            </div>
          </div>
        </div>
        <div class="col_2_item mboile_0">
          <!-- 狂暴次數 -->
          <div class="switch_box">
            <span class="name">{{$t('sale.10')}}</span>
            <Tooltip max-width="300" :content="$t('sale.11')" placement="top">
              <img src="../assets/img/question.png" class="quest" srcset />
            </Tooltip>
            <div class="base_input ml10">
              <input
                type="text"
                v-model="tradeNum"
                onkeyup="value=value.replace(/[^a-z0-9_]/g,'');"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col_2 mt20" v-show="gasMode">
        <div class="col_2_item">
          <!-- 间隔時間 (毫秒) -->
          <div class="switch_box inputset_box">
            <span class="name">{{$t('sale.14')}}</span>
            <Tooltip max-width="300" :content="$t('sale.15')" placement="top">
              <img src="../assets/img/question.png" class="quest" srcset />
            </Tooltip>
            <div class="base_input ml10">
              <input
                type="text"
                v-model="gasInterval"
                onkeyup="value=value.replace(/[^a-z0-9_]/g,'');"
              />
            </div>
          </div>
        </div>
        
      </div>
      <div class="base_btn2" @click="panicBuying">
        {{ $t("vip.4") }}
      </div>
      <div class="base_btn2"  v-if="isLoading" @click="stopBuy">
       暂停抢购
      </div>
    </div>
     <Modal
      v-model="nodeModal"
      class-name="node_modal"
      footer-hide
      :closable="false"
      width="600"
    >
        <div class="modal_header">
          <div class="modal_title">
            <img src="../assets/img/node_icon.png" alt="" srcset="">切換節點
          </div>
          <img
            src="../assets/img/vipclose.png"
            class="close_icon"
            srcset
            @click="nodeModal = false"
          />
        </div>
        <div class="align_center">
          <div class="base_input" style="flex: 1">
            <input
              type="text"
              v-model="nodeUrl"
              :placeholder="$t('tool.14')"
            />
          </div>
          <span
            class="base_btn"
            style="width: 100px; margin-left: 20px"
            @click="switchNode"
            >確認</span
          >
        </div>
    </Modal>
    <Modal
      v-model="keyModal"
      class-name="node_modal"
      footer-hide
      :closable="false"
      width="600"
    >
        <div class="modal_header">
          <div class="modal_title">
            <img src="../assets/img/private_icon.png" alt="" srcset="">加載私鑰
          </div>
          <img
            src="../assets/img/vipclose.png"
            class="close_icon"
            srcset
            @click="keyModal = false"
          />
        </div>
        <div class="align_center">
          
          <div class="base_input" style="flex: 1">
          <input
            :type="password?'text':'password'"
            v-model="privateKey"
            :placeholder="$t('tool.14')"
          />
          <img src="../assets/img/er_hide.png" class="er_img" v-show="password" @click="password=!password" alt="" srcset="">
          <img src="../assets/img/er_show.png" class="er_img" v-show="!password" @click="password=!password" alt="" srcset="">
        </div>
          <span
            class="base_btn"
            style="width: 100px; margin-left: 20px"
            @click="confirmPrivateKey"
            >確認</span
          >
        </div>
    </Modal>
  </div>
</template>
  
  <script>
import BN from "bn.js";
import {
  VipSale_ABI,
  VipSale,
  PinkSale_ABI,
  PinkSaleTest_ABI,
  DxSale_ABI,
  ERC20_ABI,
  Common_ABI,
  SwapRouter_ABI,
  DxSaleInfoAddress,
  ZERO_ADDRESS,
  MAX_INT,
  CheckVipRpc
} from "../config";

import {
  showFromWei,
  showFromWei2,
  showAccount,
  formatDecimal,
  multiply,
  toWeiMore,
  toWei,
  parseTime,
  clickOutside
} from "../utils";

export default {
    	directives: {clickOutside},

  props: {
    address: {
      type: String,
    },
    chainId: {
      type: [String, Number],
    },
  },
  data() {
    return {
            formatter1: '{value}倍',

      password: false,
      selectChain: false,
      saleUrl: "",
      saleInfo: {},
      amountIn: "",

      selectEx: 0,
      show2: false,
      show3: false,
      show4: false,
      status: false,
      value: 0,

      funStatus: false,
      delayStatus: false,
      nodeTime: 212,
      exchange: [
        {
          name: "PinkSale",
          chain: "BSC",
          logo: require("../assets/img/pinksale.png"),
        },
        {
          name: "DxSale",
          chain: "BSC",
          logo: require("../assets/img/dxsale.svg"),
        },
      ],
      selectChainId: 56, //97,
      rpcUrl: "https://bsc-dataseed.binance.org/", //"https://data-seed-prebsc-1-s3.binance.org:8545/",

      nodeModal: false,
      nodeUrl: "",
      keyModal: false,
      isLoading: false,
      isLoadingFun: null,
      privateKey: "",
      password: false,

      //是否Vip
      isVip: false,
      showEndTime: "-",
      delayInterval: 3,
      failTime: 30,
      tradeNum: 30,
      totalNum: 0,

      //gas倍数，默认1倍
      gasMulti: 1,
      gasPrice:null,
      // 燃gas模式开关
      gasMode: false,
      // 燃烧间隔
      gasInterval: 1000,

      wallet: {},
      isFirst: true,
      checking: false,
      //使用固定的gasLimit估算手续费
      gasLimit: "1000000",

      //刷新代币信息，在这里主要刷新代币池子大小
      _refreshCheckBuyIntervel: null,
    };
  },
  
  watch: {
    address() {
      if (this.address) {
        this.getTime();
      }
    },
    // chainId() {
    //   if (this.chainId) {
    //     this.handleAccountsChanged();
    //   }
    // }
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll, true);

    // console.log(11111, this.chainId, this.address);
   
    if (this.address) {
      this.getTime();
    }
  },
  beforeDestroy() {
    this.clearCheckBuyInterval();
    // window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    formatDecimal,
    handleSaleUrlChange() {
      console.log(this.saleUrl);
      this.saleInfo = {};
    },
    //获取Dx预售Id
    getDxSaleId(saleUrl) {
      let saleId = null;
      console.log("saleUrl", saleUrl);
      let scan_url = saleUrl.split("?");
      if (2 == scan_url.length) {
        scan_url = scan_url[1];
        let strs = scan_url.split("&");
        for (let x in strs) {
          let arr = strs[x].split("=");
          //链接里有saleID
          if ("saleID" == arr[0] && arr[1]) {
            return arr[1];
          }
        }
      }
      return saleId;
    },
    //確認DxsaleId
    async confirmDxSaleUrl(saleUrl) {
      let saleId = this.getDxSaleId(saleUrl);
      console.log("saleId", saleId);
      if (!saleId) {
        this.$message.error("請輸入正确的预售链接");
        return;
      }
      
      this.message = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })
      try {
       
        var Web3 = require("web3");
        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(this.rpcUrl)
        );

        let saleInfoContract = new myWeb3.eth.Contract(
          DxSale_ABI,
          DxSaleInfoAddress
        );
        //创建者地址
        let owner = await saleInfoContract.methods.presaleOwners(saleId).call();
        console.log("owner", owner);
        //预售信息合约里获取预售信息，包括代币合约
        let poolSettings = await saleInfoContract.methods
          .presales(owner)
          .call({});
        console.log("poolSettings", poolSettings);
        //代币合约
        let tokenAddress = poolSettings[3];
        //预售合约
        let saleAddress = poolSettings[4];
        console.log("tokenAddress", tokenAddress);
        //开始时间
        let startTime = parseInt(poolSettings[7]);
        //结束时间
        let endTime = parseInt(poolSettings[8]);
        //代币合约
        let tokenContract = new myWeb3.eth.Contract(ERC20_ABI, tokenAddress);
        //代币名称
        let tokenName = await tokenContract.methods.name().call();
        console.log("tokenName", tokenName);
        //代币符号
        let tokenSymbol = await tokenContract.methods.symbol().call();
        console.log("tokenSymbol", tokenSymbol);

        let saleInfo = {
          saleAddress: saleAddress,
          startTime: parseTime(startTime),
          endTime: parseTime(endTime),
          tokenAddress: tokenAddress,
          tokenName: tokenName,
          tokenSymbol: tokenSymbol,
          owner: owner,
        };

        this.saleInfo = saleInfo;

        //获取钱包余额
        if (this.wallet.privateKey) {
          this.getWalletBalance();
        }
      } catch (e) {
        console.log("e", e);
      } finally {
         this.message.close()
      }
    },
    //获取粉红预售合约地址
    getPinkSaleAddress(saleUrl) {
      let saleAddress = null;
      let pathUrl;
      let scan_url = saleUrl.split("?");
      if (2 == scan_url.length) {
        pathUrl = scan_url[0];
      } else {
        pathUrl = saleUrl;
      }

      let startIndex = pathUrl.lastIndexOf("/");
      saleAddress = pathUrl.substring(startIndex + 1);
      return saleAddress;
    },
    //确定预售链接，通过预售链接获取预售信息和代币信息
    async confirmSaleUrl() {
      let saleUrl = this.saleUrl;
      if (!saleUrl) {
        this.$message.error("請輸入预售链接");
        return;
      }
      let salePlatform = this.exchange[this.selectEx];
      //DxSale 预售平台处理
      if ("DxSale" == salePlatform.name) {
        this.confirmDxSaleUrl(saleUrl);
        return;
      }
      let saleAddress = this.getPinkSaleAddress(saleUrl);
      console.log("saleAddress", saleAddress);
      if (!saleAddress) {
        this.$message.error("請輸入正确的预售链接");
        return;
      }
      this.message = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })
      try {
       
        var Web3 = require("web3");
        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(this.rpcUrl)
        );
        let saleContract = new myWeb3.eth.Contract(PinkSale_ABI, saleAddress);
        // let saleContract = new myWeb3.eth.Contract(PinkSaleTest_ABI, saleAddress);
        //创建者地址
        let owner = await saleContract.methods.owner().call();
        console.log("owner", owner);
        //预售合约里获取预售信息，包括代币合约
        let poolSettings = await saleContract.methods.poolSettings().call({});
        //代币合约
        let tokenAddress = poolSettings[0];
        console.log("tokenAddress", tokenAddress);
        //支付代币，我们目前只做主币的预售，暂时不管
        let currency = poolSettings[1];
        //开始时间
        let startTime = parseInt(poolSettings[2]);
        //结束时间
        let endTime = parseInt(poolSettings[3]);
        //代币合约
        let tokenContract = new myWeb3.eth.Contract(ERC20_ABI, tokenAddress);
        //代币名称
        let tokenName = await tokenContract.methods.name().call();
        console.log("tokenName", tokenName);
        //代币符号
        let tokenSymbol = await tokenContract.methods.symbol().call();
        console.log("tokenSymbol", tokenSymbol);

        let saleInfo = {
          saleAddress: saleAddress,
          startTime: parseTime(startTime),
          endTime: parseTime(endTime),
          tokenAddress: tokenAddress,
          tokenName: tokenName,
          tokenSymbol: tokenSymbol,
          owner: owner,
        };

        this.saleInfo = saleInfo;

        //获取钱包余额
        if (this.wallet.privateKey) {
          this.getWalletBalance();
        }
      } catch (e) {
        console.log("e", e);
      } finally {
        this.message.close()
      }
    },

    // 增加提前下单
    addInter2() {
      this.delayInterval = parseInt(this.delayInterval) + 1;
    },
    minusInter2() {
      if (this.delayInterval == 1) return;
      this.delayInterval = parseInt(this.delayInterval) - 1;
    },
    addInter3() {
      this.gasInterval = parseInt(this.gasInterval) + 1;
    },
    minusInter3() {
      if (this.gasInterval == 1) return;
      this.gasInterval = parseInt(this.gasInterval) - 1;
    },
    // 狂暴次数
    addTime() {
      this.tradeNum = parseInt(this.tradeNum) + 1;
    },
    minusTime() {
      if (this.tradeNum == 1) return;
      this.tradeNum = parseInt(this.tradeNum) - 1;
    },

    handleScroll() {
      this.show2 = false;
    },

    initData() {},
    clickBuyModal() {
      if (this.chainId != 56) {
        this.$message.error("请连接BSC链钱包购买VIP");
        return;
      }
      this.$refs.modal.showModal();
      this.$refs.modal.getInfo();
    },
    clickNode() {
      this.nodeModal = true;
    },
    checkURL(URL) {
      var str = URL,
        Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/,
        objExp = new RegExp(Expression);
      if (objExp.test(str) == true) {
        return true;
      } else {
        return false;
      }
    },
    async switchNode() {
      if (!this.nodeUrl) {
        this.$message.error("請輸入节点地址");
        return;
      }
      if (!this.checkURL(this.nodeUrl)) {
        this.$message.error("無效的 JSON RPC 響應");
        return;
      }
      try {
       
        var Web3 = require("web3");
        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(this.nodeUrl)
        );
        console.log("myWeb3", myWeb3);

        let time = await myWeb3.eth.getGasPrice();
        console.log(time);
        this.rpcUrl = this.nodeUrl;
        this.getTime();
        this.nodeModal = false;
        this.$message(this.$t("lang.success1"));
      } catch (e) {
        console.log(e);
        this.$message.error(e.message);
      }
    },

    // 选择交易所
    switchExchange(index) {
      if (this.selectEx == index) return;
      this.selectEx = index;
      this.show2 = false;
    },

    changeGasMode(status) {
      this.gasMode = status;
      if (status) {
        this.funStatus = false;
        this.status = false;
      }
    },
    changeFun(status) {
      if (this.gasMode) {
        debugger;
        this.funStatus = false;
      } else {
        this.funStatus = status;
      }
    },
    change(status) {
      if (this.gasMode) {
        this.status = false;
      } else {
        this.status = status;
      }
    },

    async getTime() {
      var start = new Date().getTime();

      var Web3 = require("web3");
      const myWeb3 = new Web3(
        new Web3.providers.HttpProvider(this.rpcUrl)
      );
      console.log("myWeb3", myWeb3);

      // var gasPrice = await myWeb3.eth.getGasPrice();
      //   gasPrice = new BN(gasPrice, 10);
      //   //gas倍数
      //   let gasMulti = this.gasMulti;
      //   if (!gasMulti) {
      //     gasMulti = 1;
      //   }
      //   gasMulti = parseFloat(gasMulti);
      //   gasMulti = parseInt(gasMulti * 100);
      //   gasPrice = gasPrice.mul(new BN(gasMulti)).div(new BN(100));
      //   console.log("gasPricegasPrice",gasPrice)

      let gasPrice = await myWeb3.eth.getGasPrice();
      
      
      console.log("gasPrice", gasPrice)
      this.gasPrice = multiply(myWeb3.utils.fromWei(gasPrice), this.gasLimit)
      console.log("gasPrice", this.gasPrice)
      var end = new Date().getTime();
      console.log("cost is", `${end - start}ms`);
      this.nodeTime = end - start;
      this.isFirst = false;
    },
    clickPrivateKey() {
      this.keyModal = true;
    },
    //确定私钥
    async confirmPrivateKey() {
      let privateKey = this.privateKey;
      if (!privateKey) {
        return;
      }
      
      var Web3 = require("web3");
      const myWeb3 = new Web3(
        new Web3.providers.HttpProvider(this.rpcUrl)
      );
      try {
        //私钥推导账户信息
        let account = myWeb3.eth.accounts.privateKeyToAccount(privateKey);
        let wallet = {
          //私钥
          privateKey: privateKey,
          //地址
          address: account.address,
          //主币余额
          showBalance: 0,
          //USDT余额
          showUsdtBalance: 0,
        };
        //当前钱包
        console.log("私钥钱包", wallet);
        
        this.wallet = wallet;
        this.keyModal = false;
        this.getWalletBalance();
      } catch (e) {
        console.log("confirmPrivateKey", e.message);
        if(e.message.indexOf('Private key must be 32') > -1) {
          this.$message.error('私鑰必須是 32 字節長');
        } else {
          this.$message.error(e.message);
        }
      }
    },
    //获取钱包主币、USDT余额
    async getWalletBalance() {
      try {
        
        let wallet = this.wallet;
        var Web3 = require("web3");
        console.log("this.rpcUrl", this.rpcUrl);

        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(this.rpcUrl)
        );
        //获取主币余额
        let balance = await myWeb3.eth.getBalance(wallet.address);
        let showBalance = showFromWei(balance, 18, 4);
        wallet.balance = new BN(balance, 10);
        wallet.showBalance = showBalance;

        this.wallet = wallet;
        this.checkVip();
      } catch (e) {
        console.log("e", e);
         this.$message.error(e.message);
      } finally {
      }
    },
    
    //检测是否VIP，需要用检测VIP的RPC，该RPC，总是BSC链的RPC
    async checkVip() {
      try {
        let wallet = this.wallet;
        var Web3 = require("web3");
        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(CheckVipRpc)
        );
        const saleContract = new myWeb3.eth.Contract(VipSale_ABI, VipSale);
        //获取用户信息
        const userInfo = await saleContract.methods
          .getUserInfo(wallet.address)
          .call();
        console.log("userInfo", userInfo);
        //Vip过期时间
        let endTime = userInfo[1];
        let showEndTime,
            isVip = true;

          if (new BN(endTime, 10).eq(new BN(MAX_INT, 10))) {
            showEndTime = "永久会员";
          } else if (parseInt(endTime) == 0) {
            showEndTime = "未购买会员";
            isVip = false;
          } else {
            showEndTime = parseTime(parseInt(endTime));
            if(parseInt(new Date().getTime() / 1000) > parseInt(endTime)) {
              isVip = false
            }
          }
        this.isVip = isVip;
        this.showEndTime = showEndTime;
        console.log("this.isVip", this.isVip);
      } catch (e) {
        console.log("e", e);
      } finally {
      }
    },
    stopBuy() {
      this.clearCheckBuyInterval();
    },
    clearCheckBuyInterval() {
      this.isLoading = false;
      if (this.message) {
        this.message.close()
      }
      if (this._refreshCheckBuyIntervel) {
        clearInterval(this._refreshCheckBuyIntervel);
        this._refreshCheckBuyIntervel = null;
      }
    },
    //抢购
    async panicBuying() {
      this.clearCheckBuyInterval();

      if (!this.privateKey) {
        this.$message.error("請輸入私钥导入钱包");
        return;
      }
      if (!this.isVip) {
        this.$message.error("仅限VIP使用");
        return;
      }
      if (!this.amountIn) {
        this.$message.error("請輸入抢购数量");
        return;
      }
      if (!this.saleInfo || !this.saleInfo.saleAddress) {
        this.$message.error("請輸入预售链接获取预售信息");
        return;
      }

      this.isLoading = true;
      this.message = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })

      //使用之后，再刷新会员状态
      this.checkVip();

      if (!this.gasMode) {
        this._refreshCheckBuyIntervel = setInterval(() => {
          this._panicBuying();
        }, 3000);
      } else {
        this.totalNum = 0;
        this.beforeOrder();
      }
    },
    beforeOrder() {
      var d1 = new Date(this.saleInfo.startTime);
      var d2 = new Date().getTime();
      if (parseInt(d1 - d2) <= this.delayInterval * 1000) {
        this._refreshCheckBuyIntervel = setInterval(() => {
          this._panicBuying();
        }, this.gasInterval);
      } else {
        setTimeout(() => {
          this.beforeOrder();
        }, 1000);
      }
    },
    //刷新代币信息，这里主要代币池子大小
    async _panicBuying() {
      if (this.checking) {
        return;
      }
      this.checking = true;
      if (this.gasMode) {
        this.totalNum = this.totalNum + 1;
        if (this.totalNum > this.tradeNum) {
          this.stopBuy();
          return;
        }
      }

      try {
        await this._buy();
      } catch (e) {
        this.isLoading = false;
        this.message.close()
        console.log("e", e);
      } finally {
        this.checking = false;
      }
    },
    //购买过程
    async _buy() {
      let salePlatform = this.exchange[this.selectEx];
      //DxSale 预售平台处理
      if ("DxSale" == salePlatform.name) {
        this._buyDxSale();
        return;
      }
      try {
       
        let wallet = this.wallet;
        var Web3 = require("web3");
        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(this.rpcUrl)
        );
        // const saleContract = new myWeb3.eth.Contract(
        //   PinkSaleTest_ABI,
        //   this.saleInfo.saleAddress
        // );
        const saleContract = new myWeb3.eth.Contract(
          PinkSale_ABI,
          this.saleInfo.saleAddress
        );
        let amountIn;
        //输入
        amountIn = toWei(this.amountIn, 18);

        var gasPrice = await myWeb3.eth.getGasPrice();
        gasPrice = new BN(gasPrice, 10);
        //gas倍数
        let gasMulti = this.gasMulti;
        if (!gasMulti) {
          gasMulti = 1;
        }
        gasMulti = parseFloat(gasMulti);
        gasMulti = parseInt(gasMulti * 100);
        gasPrice = gasPrice.mul(new BN(gasMulti)).div(new BN(100));

        //购买预售 Data
        let data = saleContract.methods.contribute().encodeABI();

        //钱包发起交易的nonce
        var nonce = await myWeb3.eth.getTransactionCount(
          wallet.address,
          "pending"
        );
        console.log("nonce", nonce);

        //不是烧gas模式，通过预估手续费，检测交易是否成功
        if (!this.gasMode) {
          console.log("!gasMode");
          let gas = await saleContract.methods
            .contribute()
            .estimateGas({ from: wallet.address, value: amountIn });
          console.log("!gasMode111", gas);
        }

        //这里gasLimit直接用30万
        let gas = new BN(this.gasLimit, 10);

        let value = amountIn;
        //交易的to，是预售合约地址
        var txParams = {
          gas: Web3.utils.toHex(gas),
          gasPrice: Web3.utils.toHex(gasPrice),
          nonce: Web3.utils.toHex(nonce),
          chainId: this.selectChainId,
          value: Web3.utils.toHex(value),
          to: this.saleInfo.saleAddress,
          data: data,
          from: wallet.address,
        };
        console.log("txParams====", txParams);
        //gas费
        var fee = new BN(gas, 10).mul(new BN(gasPrice, 10));
        console.log("fee", Web3.utils.fromWei(fee, "ether"));

        await this._buyTx(myWeb3, wallet, txParams);
      } catch (e) {
        console.log("e", e);
      } finally {
      }
    },

    //抢购Dxsale
    async _buyDxSale() {
      try {
       
        let wallet = this.wallet;
        var Web3 = require("web3");
        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(this.rpcUrl)
        );

        let amountIn;
        //输入
        amountIn = toWei(this.amountIn, 18);

        var gasPrice = await myWeb3.eth.getGasPrice();
        gasPrice = new BN(gasPrice, 10);
        //gas倍数
        let gasMulti = this.gasMulti;
        if (!gasMulti) {
          gasMulti = 1;
        }
        gasMulti = parseFloat(gasMulti);
        gasMulti = parseInt(gasMulti * 100);
        gasPrice = gasPrice.mul(new BN(gasMulti)).div(new BN(100));

        //钱包发起交易的nonce
        var nonce = await myWeb3.eth.getTransactionCount(
          wallet.address,
          "pending"
        );
        console.log("nonce", nonce);

        //Dxsale预售方式是直接往预售合约地址转账BNB
        let to = this.saleInfo.saleAddress;
        let value = amountIn;

        //不是烧gas模式，通过预估手续费，检测交易是否成功
        if (!this.gasMode) {
          console.log("!gasMode");
          let gas = await myWeb3.eth.estimateGas({
            from: wallet.address,
            to: to,
            value: Web3.utils.toHex(value),
          });
          console.log("!gasMode", gas);
        }

        //这里gasLimit直接用30万
        let gas = new BN(this.gasLimit, 10);

        //交易的to，是预售合约地址
        var txParams = {
          gas: Web3.utils.toHex(gas),
          gasPrice: Web3.utils.toHex(gasPrice),
          nonce: Web3.utils.toHex(nonce),
          chainId: this.selectChainId,
          value: Web3.utils.toHex(value),
          to: this.saleInfo.saleAddress,
          from: wallet.address,
        };

        //gas费
        var fee = new BN(gas, 10).mul(new BN(gasPrice, 10));
        console.log("fee", Web3.utils.fromWei(fee, "ether"));

        await this._buyTx(myWeb3, wallet, txParams);
      } catch (e) {
        console.log("e", e);
      } finally {
      }
    },
    async _buyTx(myWeb3, wallet, txParams) {
      try {
        //交易签名
        let privateKey = wallet.privateKey;
        var signedTx = await myWeb3.eth.accounts.signTransaction(
          txParams,
          privateKey
        );
        //发起购买，删除定时器，烧gas模式不删除定时器
        if (!this.gasMode) {
          this.clearCheckBuyInterval();
        }

        let transaction = await myWeb3.eth.sendSignedTransaction(
          signedTx.rawTransaction
        );
        // 交易失败
        if (!transaction.status) {
          this.$message.error("购买失败");
          if (this.funStatus && !this.gasMode) {
            await this._buyTx(myWeb3, wallet, txParams);
          }
          return;
        }

        //购买成功删除定时器
        this.clearCheckBuyInterval();
        this.$message("购买成功");
        await this.getWalletBalance();
      } catch (e) {
        console.log("e", e);
      }
    },
  },
};
</script>
  
<style lang="less">
@import "../assets/pc/sale.less";
@import "../assets/mobile/sale.less";
</style>